<template>
    <v-row v-if="isLoading" justify="center">
        <v-col cols="6" lg="3" v-for="item in 3" :key="item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>
    <v-row v-else :align="'stretch'" justify="center">

          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar class="elevation-3 text-center mt-2" color="primary" size="38" style="margin: auto;" >
                  <v-icon color="white " > mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Total cartes</p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{
                    CurrencyFormatting(statistiques.cards_amount)
                  }}
                  DZD
                </p>

                             
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%">
              <v-card-title class="align-start">
                <v-avatar class="elevation-3" color="success" size="38">
                  <v-icon color="white"> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Total consomation</p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{
                    CurrencyFormatting(statistiques.consumed_cards_amount)
                      
                  }}
                  DZD
                </p>

                <div class="d-block mt-3">
                    <v-progress-linear
                        v-model="statistiques.consumed_cards_percent"
                        color="success accent-4"
                    ></v-progress-linear>
                    <p class="text--primary subtitle-1 mb-1 px-5 text-center">{{statistiques.consumed_cards_percent }} % </p>
                </div>

              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%">
              <v-card-title class="align-start">
                <v-avatar class="elevation-3" color="error" size="38">
                  <v-icon color="white"> mdi-database</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Total non consommé</p>
                <p class="font-weight-black text-lg mb-1 title">
                  <!-- {{ $store.state.dashboardData.salepoint_count }} -->

                  {{
                    CurrencyFormatting(statistiques.not_consumed_cards_amount)
                  }}
                  DZD
                </p>

                <div class="d-block mt-3" >
                    <v-progress-linear
                        v-model="statistiques.not_consumed_cards_percent"
                        color="success accent-4"
                    ></v-progress-linear>
                    <p class="text--primary subtitle-1 mb-1 px-5 text-center">{{statistiques.not_consumed_cards_percent }} % </p>

                </div>

              </v-card-text>
            </v-sheet>
          </v-col>


        </v-row>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        fetchData(){
            this.$store.dispatch("fetchStatistiquesCards");
        },
    },
    computed: {
        isLoading() {
            return this.$store.getters.getStatsLoading;
        },
        statistiques() {
            return this.$store.getters.getCardsStatistiques;
        },
    },
    mounted() {
        this.fetchData();
    }
}
</script>